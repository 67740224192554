import React from 'react';
import { css } from '@emotion/core';
import presets from '../../styles/presets';
import { ContentfulAsset } from '../../graphql-types';

import image from '../../assets/images/about-hiring-illustration.svg';

interface ImageBelowHiringProps {
  imageSmall: ContentfulAsset;
}

const ImageBelowHiring: React.FC<ImageBelowHiringProps> = ({ imageSmall }) => {
  return (
    <div
      css={css`
        max-width: 1560px;
        margin: 38px auto 86px;

        ${presets.md} {
          padding: 0 24px;
          margin: -20px auto 86px;
        }

        ${presets.xl} {
          padding: 0 34px;
          margin: 72px auto 172px;
        }
      `}
    >
      <div
        css={css`
          background-image: url(${imageSmall?.fluid?.src});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 50%;

          height: 61.5vw;

          ${presets.md} {
            background-image: url(${image});
            background-size: contain;
            max-height: 520px;
          }
        `}
      />
    </div>
  );
};

export default ImageBelowHiring;
