import React from 'react';
import HeadingSection from '../HeadingSection/HeadingSection';
import { Container, Row, Col } from 'emotion-flex';
import { ContentfulContentBlock } from '../../graphql-types';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';
import presets from '../../styles/presets';
import { css } from '@emotion/core';

import image1 from '../../assets/images/about-thisiscara-1.png';
import image2 from '../../assets/images/about-thisiscara-2.svg';
import image3 from '../../assets/images/about-thisiscara-3.svg';
import imageBelowHighlights from '../../assets/images/about-certified-illustration.svg';

const images = [image1, image2, image3];

const Highlights: React.FC<ContentfulContentBlock> = ({ items, ...rest }) => {
  return (
    <>
      <section
        css={{
          marginBottom: 90,
          [presets.xl]: {
            marginBottom: 238,
          },
        }}
      >
        <HeadingSection {...rest} />
        <Container css={{ margin: '114px auto 0' }}>
          <div
            css={{
              [presets.lg]: {
                marginLeft: 80,
              },
            }}
          >
            <Row>
              {items.map((item, index) => {
                return (
                  <Col
                    key={item.heading}
                    xs={12}
                    lg={4}
                    css={{
                      textAlign: 'center',
                      [presets.lg]: {
                        textAlign: 'left',
                      },
                    }}
                  >
                    <div
                      css={{
                        minHeight: 240,
                        position: 'relative',
                        [presets.xl]: {
                          minHeight: 295,
                        },
                      }}
                    >
                      <img
                        src={images[index]}
                        alt={item.heading}
                        css={{
                          maxWidth: '60%',
                          height: 'auto',
                          margin: '0 auto',

                          [presets.md]: {
                            maxWidth: '50%',
                          },
                          [presets.lg]: {
                            position: 'absolute',
                            left: index < 2 ? -36 : -6,
                            maxWidth: '90%',
                          },
                        }}
                      />
                    </div>
                    <h5
                      css={{
                        fontFamily: ['Blacker Pro Display']
                          .concat(SYSTEM_FONTS)
                          .join(', '),
                        fontSize: 20,
                        fontWeight: 600,
                        color: '#160B2C',
                        marginTop: 30,
                        marginBottom: 18,
                        [presets.xl]: {
                          marginTop: 48,
                        },
                      }}
                    >
                      {item.heading}
                    </h5>
                    <p
                      css={{
                        fontFamily: ['Open Sans']
                          .concat(SYSTEM_FONTS)
                          .join(', '),
                        fontSize: 16,
                        color: hexToRgba('#160B2C', 0.8),
                        marginBottom: 80,
                        [presets.xl]: {
                          marginBottom: 50,
                        },
                      }}
                    >
                      {item.shortText}
                    </p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </section>
      {/* Image Below Section Highlights */}
      {/* Hack here: 
          x = the same lvl as previous/next section
          y = margin as designed
          margin = x + y
      */}
      <div
        css={{
          marginTop: -358 + 122,
          marginBottom: -290 + 70,

          [presets.sm]: {
            marginTop: -688 + 240,
            marginBottom: -480 + 88,
          },
        }}
      >
        <div
          css={css`
            background-image: url(${imageBelowHighlights});
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            min-height: 135vw;
            margin: 0 auto 90px;

            ${presets.sm} {
              min-height: 1100px;
            }
          `}
        />
      </div>
    </>
  );
};

export default Highlights;
