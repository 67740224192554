import React from 'react';
import { ContentfulContentBlock } from '../../graphql-types';
import { SYSTEM_FONTS } from '../../styles/typography';
import { ButtonLink } from '../common/Button';
import Container from '../Homepage/Container';
import { MonoFontLabel } from '../common/typography';
import presets from '../../styles/presets';
import hexToRgba from 'hex-to-rgba';
import { css } from '@emotion/core';

interface HeadingSectionProps extends ContentfulContentBlock {
  variant?: 'primary' | 'primary-left';
  white?: boolean;
}

const HeadingSection: React.FC<HeadingSectionProps> = ({
  prefix,
  title,
  text,
  callToAction,
  variant = 'primary',
  white = false,
}) => {
  const textAlign = variant === 'primary-left' ? 'left' : 'center';

  return (
    <Container
      css={{
        maxWidth: 509 + 24 * 2,
        textAlign,
      }}
    >
      {prefix && <MonoFontLabel>{prefix}</MonoFontLabel>}
      <h4
        css={{
          fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
          fontSize: 28,
          color: white ? '#fff' : '#160B2C',
          marginBottom: 18,
          [presets.md]: {
            fontSize: 40,
          },
        }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        css={{
          fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
          fontSize: 14,
          color: hexToRgba(white ? '#fff' : '#160B2C', 0.7),
          marginBottom: 36,

          [presets.lg]: {
            fontSize: 18,
            color: hexToRgba(white ? '#fff' : '#160B2C', 0.8),
          },
        }}
      >
        {text && text.childMarkdownRemark.rawMarkdownBody}
      </p>
      {callToAction && callToAction.isDisplayed && callToAction.url && (
        <ButtonLink
          to={callToAction.url}
          css={css`
            @media (max-width: 500px) {
              padding: 1rem;
              font-size: ${callToAction.text.length > 20
                ? callToAction.text.length > 30
                  ? '0.52rem'
                  : '0.775rem'
                : '0.875rem'};
            }
          `}
        >
          {callToAction.text}
        </ButtonLink>
      )}
    </Container>
  );
};

export default HeadingSection;
